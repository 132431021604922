.dev-container {
    max-width: 1024px;
    margin: 0px auto;
    padding: 10px;
    color: black;
    padding-bottom: 50px;
}

.dev-container h2 {
    font-size: 48px;
    text-align: center;
}

.font {
    font-family:'Monofett';
}

.padding {
    padding-top: 25px;
}

a:hover,a:visited {
     color:#000; 
}