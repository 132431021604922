*{font-family: 'Permanent Marker';}
*{background-color: pink;}

.jumbotron {
  margin-top: 30px;
  margin-bottom: 60px;
  background-color: pink;
}

.jt-container {
  max-width: 1024px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

hr{
	margin: 40px;
	border: none;
	height: 4px;  
}
hr.rainbow{
	background-image: linear-gradient(to right, rgba(255,2,5,.5), rgba(137,11,138,.5), rgba(27,123,187,.5), rgba(47,195,44,.5), rgba(253,201,1,.5), rgba(255,91,2,.5), #ccc 45%, #ccc 55%, rgba(255,91,2,.5), rgba(253,201,1,.5), rgba(47,195,44,.5), rgba(27,123,187,.5), rgba(137,11,138,.5), rgba(255,2,5,.5));
} 

.main-bg {
  background-image: linear-gradient(-90deg, rgb(49, 46, 46), rgb(99, 99, 155));
}