.faq-container {
    max-width: 1024px;
    margin: 0px auto;
    padding: 10px;
    color: black;
    padding-bottom: 50px;
}

.faq-container h2 {
    font-size: 48px;
    text-align: center;
}

.font {
    font-family:'Monofett';
}