.nav {  
    opacity: 0.5; 
    filter:(opacity=50);       
}

.size {
    font-size: 1.875em;
}

.font {
    font-family:'Monofett';
}

@media only screen and (max-width: 768px) {

    .size {
        font-size: 1.4em;
    }

}