/* .main-app {
    margin: 100px;
    position: absolute;
  } */

  .mint-container {
    max-width: 1024px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding: 10px;
  }

  .padding {
    padding-top: 25px;
}
  
  .cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  .connect-wallet-button {
    background: rgb(236, 56, 236);
  }
  
  .mint-nft-button {
    background: orange;
  }

.jt-image img {
    border-radius: 15px;
}

.vertical-center {
  align-items: center;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.fs {
  font-size: large;
}

.pd {
  padding-left: 50px;
  padding-right: 50px;
}

.countdown {
  font-size: 50px;
}

.resize {
  width:1px; /* you can use % */
  height:1px;
}

label {
  font-weight: bold;
  display: block;
  }
  label:before { content: "Amount : " }

@media only screen and (max-width: 768px) {

  .jumbotron {
    padding: 0px;
  }
  
  .mint-container {
      display: block;
      text-align: center;
  }

  .jt-image img {
    max-width: 256px;
    margin: 0px auto;
  }

  .mobile {
    padding-top: 25px;
  }

  .pd {
    padding-left: 0px;
    padding-right: 0px;
  }

  .countdown {
    font-size: 30px;
  }

}