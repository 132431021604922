.about-container {
    max-width: 1024px;
    margin: 0px auto;
    padding: 10px;
    color: black;
    padding-bottom: 50px;
}

.about-container h2 {
    font-size: 48px;
    text-align: center;
}

.about-gallery img {
    max-width: 256px;
    border-radius: 15px;
}

.font {
    font-family:'Monofett';
}

.fs {
    text-align: center;
    font-size: 20px;
}

.padding {
    padding-top: 25px;
}

img.resize {
    width:245px; /* you can use % */
    height: auto;
}

@media only screen and (max-width: 768px) {
  
    .about-container {
        display: block;
        text-align: center;
    }
  
    img.resize {
      max-width: 256px;
      margin: 0px auto;
  }
  
  }